import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// import { Link } from 'react-router-dom';
import { useCarbonCreditStore } from '@/store/carbonCredit';
import { useWishListStore } from '@/store/wishList';
import { formatNumberByComma } from '@/util/helper';

import CustomButton from '../CustomButton';
import Navbar from '../Navbar';
import CreditRetiredModal from './CreditRetiredModal';
import GoalsTile from './GoalsTile';
import ImgSlider from './ImgSlider';
import NavigationTabs from './NavigationTabs';

interface State extends SnackbarOrigin {
  open: boolean;
}

const NewProductDetails = () => {
  const mapKey = useMemo(() => process.env.REACT_APP_GOOGLE_MAP_API_KEY, []);
  const [idInWishlist, setIdInWishlist] = useState<number>(0);
  const [isSnackOpen, setIsSnackOpen] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const [msg, setMsg] = useState<string>('');
  const { vertical, horizontal, open } = isSnackOpen;
  const param = useParams();
  const carbonCredit = useCarbonCreditStore((state) => state.carbonCredit);
  const getCarbonCredit = useCarbonCreditStore((state) => state.getCarbonCredit);
  const addToWhishList = useWishListStore((store) => store.addToWhishList);
  const deleteWishList = useWishListStore((store) => store.deleteWishList);
  const wishList = useWishListStore((store) => store.wishList);
  const getWishList = useWishListStore((store) => store.getWishList);
  const hanleOpen = () => {
    setIsSnackOpen({ ...isSnackOpen, open: true });
  };
  const handleClose = () => {
    setIsSnackOpen({ ...isSnackOpen, open: false });
  };
  const addLike = async (id: number, msg: string) => {
    await addToWhishList(id);
    setMsg(msg);
    hanleOpen();
  };
  const removeLike = async (id: number, msg: string) => {
    await deleteWishList(id);
    setMsg(msg);
    hanleOpen();
  };
  // console.log(carbonCredit);

  useEffect(() => {
    const idInWishlist = wishList.find((item) => param?.id && item.carbon_credit === +param?.id)?.id || 0;
    setIdInWishlist(idInWishlist);
  }, [wishList]);

  useEffect(() => {
    if (!param.id) return;
    getCarbonCredit(Number(param.id));
    getWishList();
  }, []);

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const type = params.get('type');

  return (
    <div
      className={classNames('bg-no-repeat bg-cover bg-center h-screen min-h-[700px]', {
        "bg-[url('../public/images/products/green/bg-green.png')]": type === 'green' || !type,
        "bg-[url('../public/images/products/yellow/bg-yellow.png')]": type === 'yellow',
        "bg-[url('../public/images/products/blue/bg-blue.png')]": type === 'blue',
        "bg-[url('../public/images/products/other/bg-other.png')]": type === 'other'
      })}
    >
      <div className="relative py-4">
        <Navbar />
      </div>
      <div className="max-w-[1440px] mx-auto">
        <div className=" overflow-y-scroll overflow-x-hidden yellowScroll 2xl:h-[90vh] h-[87vh] mr-[17px] px-8">
          <img
            src="/scroller-icon.svg"
            alt="sacurn"
            className="w-[41.349px] h-[41.349px] absolute bottom-10 right-12 z-10"
            onClick={() => {
              if (
                document.getElementById('section-2') ||
                document.getElementById('section-3') ||
                document.getElementById('section-4')
              ) {
                document.getElementById('section-1')?.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          />
          <div id="section-1">
            <div className="flex 2xl:gap-10 gap-5 mb-6">
              <div className="max-w-[300px]">
                <img
                  src={carbonCredit.impact_image || ''}
                  alt="sacurn"
                  className={classNames('h-full w-full object-cover border-[5px] rounded-[14px]', {
                    'border-light-green': type === 'green',
                    'border-yellow': type === 'yellow',
                    'border-navy-blue': type === 'blue',
                    'border-white': type === 'other'
                  })}
                />
              </div>

              <div className="font-istok-web w-full min-h-[203px] flex flex-col justify-between">
                <div>
                  <h2 className="2xl:text-[30px] text-[22px] font-bold text-white leading-normal font-istok-web mb-4">
                    {carbonCredit.name}
                  </h2>
                  <div className="flex text-white mb-3">
                    <LocationOnOutlinedIcon fontSize="small" className="mr-1" />
                    <span className="mr-4">Region</span>
                    <span className="font-bold"> {carbonCredit.location}</span>
                  </div>
                  <div className="flex text-white">
                    <BookOutlinedIcon fontSize="small" className="mr-1" />
                    <p className="mr-4">Carbon Credit Registry</p>
                    <p className="font-bold"> {carbonCredit.certification_from}</p>
                  </div>
                </div>
                {idInWishlist > 0 ? (
                  <CustomButton
                    className="bg-white rounded-lg py-3 px-4 !text-dark-grey border-white border w-fit self-end"
                    onClick={() => removeLike(idInWishlist, 'Removed from wishlist')}
                  >
                    Remove from Wish List
                    <FavoriteRoundedIcon className="ml-1" />
                  </CustomButton>
                ) : (
                  <CustomButton
                    className="bg-pale-yellow rounded-lg py-3 px-4 !text-dark-grey border-white border w-fit self-end"
                    onClick={() => addLike(carbonCredit?.id, 'Added to wishlist')}
                  >
                    Add to Wish List
                    <FavoriteBorderOutlinedIcon className="ml-1" />
                  </CustomButton>
                )}
              </div>
            </div>
          </div>

          {/* <div
          className="xl:w-[217px] w-[180px] h-9.5 border border-[#A0ADB7] rounded-[10px] flex items-center justify-center gap-2.5 ml-auto shadow-[0px 1.788px 8.342px 0px rgba(0, 0, 0, 0.45), 0px 0px 10.726px 0px rgba(0, 84, 135, 0.20)] mr-4.2 my-2.5 hover:bg-[#dfdfdf41] cursor-pointer active:translate-y-0.5"
          onClick={() => navigate('/')}
        >
          <p className="font-normal text-base text-white">Back</p>
          <img src="/images/products-page/back-icon.svg" alt="sacurn" width={23} height={16} />
        </div> */}
          <div>
            <div className="border bg-[#1b1b1b4d] border-pale-yellow pt-[33px] px-[30px]">
              <NavigationTabs active={1} className="mb-[21px]" />
              <div className="gap-[72px] flex items-center justify-center">
                <div className="h-[1px] bg-white w-[32%]"></div>
                <h3 className="text-[28px] leading-10 text-white font-bold my-4 text-nowrap">Project Description</h3>
                <div className="h-[1.3px] bg-white w-[32%]"></div>
              </div>
              <div className="text-white indent-9 text-justify font-istok-web text-xl font-normal leading-7 tracking-[1.4px] mb-10">
                <p>{carbonCredit.information}</p>
              </div>
              {/* <div className="flex gap-[33px] mb-[58px]">
              {carbonCredit.impacts?.map((impact) => {
                return (
                  <div key={impact.id} className="border border-white flex-1 px-8 py-2.7 flex gap-[22px]">
                    <div className="flex items-center">
                      <img src={impact.icon} width={55} height={40} className="w-[55px] h-10" alt={impact.name} />
                    </div>
                    <div>
                      <div className="flex gap-4 items-center">
                        <h5 className="text-2xl font-bold text-white font-istok-web">{impact.name}</h5>
                      </div>
                      <p className="text-white text-lg font-normal">{impact.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div> */}
              <div className="py-5 ">
                <div className="flex flex-col gap-5">
                  <div className="flex justify-between">
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Project ID</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.project_code}</p>
                    </div>
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Geographic Location</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.location}</p>
                    </div>
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Project Type</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.type}</p>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Vintages</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">
                        {carbonCredit.available_vintages}
                      </p>
                    </div>
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Validation-Verification Body</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">
                        {carbonCredit.validation_verification_body || ''}
                      </p>
                    </div>
                    {/* <div className="max-w-[440px] flex-1 px-2">
                    <p className="product-detail-title">Hectares</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">
                      {formatNumberByComma(carbonCredit.hectares || '')}
                    </p>
                  </div> */}
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Project Developer</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.developer}</p>
                    </div>
                  </div>
                </div>

                <hr className="h-[1px] bg-light-grey my-[30px] pt-[2.5px]" />

                <div className="flex flex-wrap justify-between">
                  <div className="basis-1/3 max-w-[440px] px-2">
                    <p className="product-detail-title">Standard</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.standard}</p>
                  </div>
                  <div className="basis-1/3 max-w-[440px] px-2">
                    <p className="product-detail-title">Methodology</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.methodology}</p>
                  </div>
                  <div className="basis-1/3 max-w-[440px] px-2">
                    <p className="product-detail-title">CORSIA Eligibility</p>
                    {carbonCredit.ccs_validator?.map((validator) => {
                      return (
                        <div key={validator.name}>
                          <p className="text-lg font-bold tracking-[0.54px] text-white">{validator.name}</p>
                          <p className="text-lg font-bold tracking-[0.54px] text-white">({validator.desc})</p>
                        </div>
                      );
                    })}
                    {/* <p className="text-lg font-bold tracking-[0.54px] text-white">
                        {carbonCredit.standard_methodology}
                      </p> */}
                  </div>
                  {carbonCredit.additional_certifications && (
                    <div className="basis-1/3 mt-4 max-w-[440px] px-2">
                      <p className="product-detail-title">Additional Certifications</p>
                      {Array.isArray(carbonCredit.additional_certifications) ? (
                        carbonCredit.additional_certifications?.map((certification) => {
                          return (
                            <p key={certification.name} className="text-lg font-bold tracking-[0.54px] text-white">
                              {certification.name}
                            </p>
                          );
                        })
                      ) : (
                        <p className="text-lg font-bold tracking-[0.54px] text-white">
                          {carbonCredit.additional_certifications}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <hr className="h-[1px] bg-light-grey my-[30px] pt-[2.5px]" />

                <div className="flex flex-col gap-11">
                  <div className="flex justify-between">
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Total Issuance</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">
                        {formatNumberByComma(carbonCredit.volume_issued || '')}
                      </p>
                    </div>
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Estimated Annual Emission Reductions</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">
                        {formatNumberByComma(carbonCredit.avg_annual_emission_reduction || '')}
                      </p>
                    </div>
                    <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Crediting Period</p>
                      <p className="text-lg font-bold tracking-[0.54px] text-white">
                        {carbonCredit.crediting_period || ''}
                      </p>
                    </div>

                    {/* <div className="max-w-[440px] flex-1 px-2">
                      <p className="product-detail-title">Crediting Period</p>
                      {carbonCredit.issued_credits_retired?.map((carbon) => {
                        return (
                          <p
                            key={carbon.year}
                            className="text-lg font-bold tracking-[0.54px] text-white font-istok-web"
                          >
                            {carbon.year} - {carbon.percentage}
                          </p>
                        );
                      })}
                      <CreditRetiredModal issuedList={carbonCredit.issued_credits_retired} />
                    </div> */}
                  </div>
                  {/* <div className="flex justify-between">
                  <div className="max-w-[440px] flex-1 px-2">
                    <p className="product-detail-title">Project Documents </p>
                    <div className="flex gap-[33.33px]">
                      {carbonCredit.project_documents?.map((doc) => {
                        return (
                          <a key={doc.url} href={doc.url || ''} target="_blank" rel="noopener noreferrer">
                            <div className="flex gap-2.7">
                              <p className="text-lg font-bold tracking-[0.54px] text-white">{doc.name}</p>
                              <img src="/images/products/external-link.svg" alt="info" />
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div> */}
                </div>

                <hr className="h-[1px] bg-light-grey mt-10 mb-6 pt-[2.5px] mx-auto" id="section-2" />
              </div>
              <p className="product-detail-title mb-2">Satellite Image</p>

              {carbonCredit.map_url ? (
                <div className="w-full h-[600px]">
                  <iframe
                    loading="lazy"
                    style={{ border: 0, margin: 'auto', maxWidth: '100%', width: '100%', height: '100%' }}
                    allowFullScreen
                    src={`${carbonCredit.map_url}&key=${mapKey}`}
                  ></iframe>
                </div>
              ) : (
                <div className="w-full h-[700px]">
                  <img src={carbonCredit.map || ''} alt="impact" className="w-full h-full object-cover" />
                </div>
              )}

              <div id="section-3"></div>
              <NavigationTabs active={3} className="mt-15 mb-12" />
              <div className="max-w-[1000px] mx-auto">
                {/* <img src={carbonCredit.impact_image || ''} alt="impact" className="w-full h-full object-cover" /> */}
                <ImgSlider images={carbonCredit.image_list || []} />
              </div>
              <h3 className="text-[22px] font-bold tracking-[0.75px] text-white text-center px-3 border-l-4 border-r-4 border-white w-fit mx-auto my-[33px]">
                This project contributes to {carbonCredit.sdg_list?.length || 0} Sustainable Development Goals
              </h3>
              {/* create grid with twoo cards in each row */}
              <div className="grid grid-cols-2 mb-7 gap-y-5 gap-x-[12.4%] max-w-[1635px] mx-auto" id="section-4">
                {carbonCredit.sdg_list?.map((sdgItem) => (
                  <GoalsTile
                    key={sdgItem.id}
                    img={sdgItem.icon}
                    title={sdgItem.name}
                    description={sdgItem.desc}
                    content={sdgItem.content}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={msg}
        key={vertical + horizontal}
      />
    </div>
  );
};

export default NewProductDetails;
