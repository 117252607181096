import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ExtendedCart as CartItemType } from '@/libs/api';
import { useCartStore } from '@/store/cart';
import { ModalType, useModalStore } from '@/store/modal';
import { DeleteCart, MinusRounded, PlusRounded } from '@/svg';
import { OrderStatus } from '@/type';
import { MIN_CART_QTY } from '@/util/constants';
import { formatNumberByComma } from '@/util/helper';
import isValidNumber from '@/util/isValidNumber';

import Navbar from '../components/Navbar';

interface HeadingIProps {
  children: React.ReactNode;
}

const TAX_PERCENTAGE = 0.05;

const Cart = () => {
  const navigate = useNavigate();
  const cartList = useCartStore((store) => store.cartList);
  const getCartList = useCartStore((store) => store.getCartList);
  const cartDetail = useCartStore((store) => store.cartDetail);
  const updateCartItemSelected = useCartStore((store) => store.updateCartItemSelected);
  const isSelectedAll = useCartStore((store) => store.isSelectedAll);
  const setAllCartItemSelect = useCartStore((store) => store.setAllCartItemSelect);
  const deleteSelectedCartItem = useCartStore((store) => store.deleteSelectedCartItem);
  const checkPassMinOrderThreshold = useCartStore((store) => store.checkPassMinOrderThreshold);
  const open = useModalStore((store) => store.open);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getCartList();
  }, []);

  const taxPercentage = useMemo(() => {
    return (cartDetail?.tax_ratio || TAX_PERCENTAGE) * 100;
  }, []);

  return (
    <div className="cart bg-neutral-150 h-screen overflow-hidden">
      <Navbar className="h-[70px] !bg-navy-blue" />
      <div className="px-10">
        <div className="flex justify-between items-center my-4 xl:pr-5 w-[65%]">
          <Link to="/">
            <button className="rounded-[3px] px-4 py-1 flex items-center justify-center border border-navy-blue text-navy-blue text-sm whitespace-nowrap">
              <img src="/images/cart/ic_back.svg" width={16} height={14} className="mr-2.5" alt="sacurn" />
              繼續購物
            </button>
          </Link>
          <p className="text-xl mx-4 whitespace-nowrap block text-navy-blue font-bold">
            <span className="mr-3">|</span>購物車
          </p>
          <div className=" ml-auto mr-2 flex items-center gap-2 py-2 px-4 shadow-sm bg-white rounded-[10px] cursor-pointer">
            <span className="text-sm font-bold whitespace-nowrap">全選</span>
            <input
              type="radio"
              className="w-5 h-5"
              checked={isSelectedAll()}
              onChange={() => {
                setAllCartItemSelect(!isSelectedAll());
              }}
            />
          </div>
          <div
            className="flex gap-1 items-center py-2 px-4 shadow-sm bg-white rounded-[10px] cursor-pointer"
            onClick={deleteSelectedCartItem}
          >
            <span className="text-sm font-bold whitespace-nowrap">刪除選取</span>
            <DeleteIcon sx={{ color: grey[500] }} />
          </div>
        </div>
        <div className="flex flex-row gap-6 pb-10">
          <div
            className={classNames(
              'w-[65%] max-h-[80vh] min-w-[455px] pb-4 flex gap-4 flex-col yellowScroll yellowScrollHorizontal overflow-y-auto pr-3 rounded-[10px]'
              // {
              //   'w-[65%]': cartDetail,
              //   'w-full': !cartDetail
              // }
            )}
          >
            {cartList.map((item, index) => (
              <CartItem
                key={item.id}
                onSelectedChange={(selected: boolean) => {
                  updateCartItemSelected(item.id, index, selected);
                }}
                {...item}
              />
            ))}
          </div>
          {cartDetail && (
            <div className="max-h-[80vh] min-w-[340px] flex-1  overflow-y-auto yellowScroll pr-3 pb-4">
              <div className="flex flex-col py-6 rounded-[10px] bg-white shadow-cart-item">
                <div className="flex flex-row justify-between pr-6.7">
                  <Heading>商品共計</Heading>
                  <p className="2xl:text-lg text-base text-black font-bold font-istok-web">
                    TWD {formatNumberByComma(cartDetail?.product_amount?.toString() as string)}
                  </p>
                </div>
                <div className="px-6.7 mt-2.5 ">
                  <p className="text-grey opacity-60 font-bold font-istok-web text-xs">
                    {cartDetail && cartDetail.product_list?.length} 項 (以下含稅金 {taxPercentage} %及手續費)
                  </p>
                  <div className="2xl:mt-5.2 mt-3">
                    {cartDetail &&
                      cartDetail.product_list?.map((product) => {
                        return (
                          <div key={product.name} className="flex flex-row justify-between  mb-3">
                            <p className="w-[70%] totall-item-title ">{product.name}</p>
                            <p className="totall-item-title">
                              $ {formatNumberByComma(product.amount.toString() as string)}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                  <div className="flex flex-row justify-between 2xl:mb-5 mb-3">
                    <p className="totall-item-title">手續費</p>
                    <p className="totall-item-title">$ {formatNumberByComma(cartDetail?.cost?.toString() as string)}</p>
                  </div>
                  <div className="flex flex-row justify-between 2xl:mb-6.2 mb-3">
                    <p className="totall-item-title">稅金{taxPercentage}%</p>
                    <p className="totall-item-title">$ {formatNumberByComma(cartDetail?.tax?.toString() as string)}</p>
                  </div>
                  <div className="mt-4 flex flex-row justify-between">
                    <p className="totall-item-title !text-black">總付款金額</p>
                    <p className="totall-item-title !text-bright-red ">
                      TWD {formatNumberByComma(cartDetail?.total_amount?.toString() as string)}
                    </p>
                  </div>
                </div>
                <hr className="border-silverstone 2xl:mt-13.2 mt-4 2xl:mb-6 mb-4" />
                <Heading>服務條款</Heading>
                <p className="ml-6 text-sm mt-4 text-navy-blue">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => {
                      setIsChecked(!isChecked);
                      if (e.target.checked) {
                        setError(false);
                      }
                    }}
                    className="text-[#F00] mr-2"
                  />
                  我瞭解並同意Sacurn
                  <Link
                    to="/pdf/Membership_Terms_Service.pdf"
                    target="_blank"
                    download="土星_平台條款內容"
                    className="hover:underline cursor-pointer"
                  >
                    服務條款
                  </Link>
                  與
                  <Link
                    to="/pdf/Membership_Terms_Service.pdf"
                    target="_blank"
                    download="土星_平台條款內容"
                    className="hover:underline cursor-pointer"
                  >
                    隱私權政策
                  </Link>
                </p>
                {error && <p className="text-[#f00] text-xs ml-12">請務必確認勾選此框，才能點選「前往付款」。</p>}
                <hr className="border-silverstone 2xl:mt-8 mt-4 2xl:mb-5 mb-3" />
                <p className="my-2 text-xms text-dark-grey self-center  font-istok-web">
                  點擊「前往付款」，訂單及送出，請於下一步選擇付款方式
                </p>
                <button
                  onClick={async () => {
                    if (!isChecked) return setError(true);

                    const isPass = await checkPassMinOrderThreshold();

                    if (isPass) {
                      open(ModalType.CheckOutConfirm, {
                        buttons: [
                          {
                            text: '返回商品列表',
                            isOutline: true
                          },
                          {
                            text: '確認結帳',
                            onClick: () => {
                              navigate('/payment-information');
                            }
                          }
                        ]
                      });
                    } else {
                      open(ModalType.NotPassMinOrderThreshold);
                    }
                  }}
                  className={classNames(
                    'w-[80%] py-2 self-center rounded-md 2xl:text-xl text-lg font-bold text-white mb-3',
                    {
                      ['bg-navy-blue']: isChecked,
                      ['bg-grey']: !isChecked
                    }
                  )}
                >
                  前往付款
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;

interface CartItemIProps extends CartItemType {
  selected: boolean;
  onSelectedChange: (selected: boolean) => void;
}

const CartItem = (props: CartItemIProps) => {
  const {
    selected,
    id,
    name,
    cover_image,
    remaining_quantity,
    order,
    order_deleted,
    company_code,
    carbon_tag,
    onSelectedChange
  } = props;

  const [qty, setQty] = useState(props.quantity || MIN_CART_QTY);

  const updateCartItemQty = useCartStore((store) => store.updateCartItemQty);
  const deleteCartItem = useCartStore((store) => store.deleteCartItem);
  const open = useModalStore((store) => store.open);

  const price = props.price || 0;

  const onDeleteCartItem = useCallback(() => {
    open(ModalType.DeleteCartItem, {
      buttons: [
        { text: '取消', isOutline: true },
        { text: '確定', onClick: () => deleteCartItem(id) }
      ]
    });
  }, [id]);

  const isOffShelve = useMemo(() => order_deleted === OrderStatus.OffShelve, []);
  const isCannotBuy = remaining_quantity === '0';

  const onQuantityAdjust = useCallback(
    (newQty: number) => {
      if (isOffShelve) return;
      if (newQty >= MIN_CART_QTY && newQty <= parseInt(remaining_quantity)) {
        setQty(newQty);
        updateCartItemQty(id, {
          quantity: newQty,
          order
        });
      }
    },
    [qty]
  );

  return (
    <div
      className={classNames(
        'flex items-center justify-between py-4 rounded-lg direction-ltr shadow-cart-item px-6 border-l-8',
        {
          'bg-slight-blue': selected,
          'bg-white': !selected,
          'border-light-green': carbon_tag === '綠碳',
          'border-light-blue': carbon_tag === '藍碳',
          'border-pale-yellow': carbon_tag === '黃碳',
          'border-grey': carbon_tag === '其他'
        }
      )}
      onClick={() => {
        if (isOffShelve) return;
        onSelectedChange(!selected);
      }}
    >
      <div className="flex items-center justify-between max-w-[60%]">
        <div className="min-w-[20px]">
          {selected ? (
            <img src="/images/cart/ic_check.svg" width={29} height={29} alt="sacurn" />
          ) : (
            <img src="/images/cart/ic_uncheck.svg" width={29} height={29} alt="sacurn" />
          )}
        </div>
        <div className="w-[114px] aspect-square ml-4">
          <img
            src={cover_image}
            className={classNames('w-full h-full object-cover rounded-[10px]', {
              'border-[3.408px] border-light-green': carbon_tag === '綠碳',
              'border-[3.408px] border-light-blue': carbon_tag === '藍碳',
              'border-[3.408px] border-pale-yellow': carbon_tag === '黃碳',
              'border-[3.408px] border-grey': carbon_tag === '其他'
            })}
            alt="sacurn"
          />
        </div>
        <div className="ml-5 flex flex-col justify-between h-full w-[180px] min-w-[180px] xl:min-w-[280px]">
          <p className="text-[10px] font-bold text-dark-grey">會員代號 : {company_code}</p>
          <p
            className={classNames('font-bold text-lg leading-[20px]  mr-2 my-2', {
              'text-bright-blue': selected,
              'text-black': !selected
            })}
          >
            {name}
          </p>
          <p className="text-lg font-bold text-black">$ {formatNumberByComma(price.toString() as string)}/噸</p>
        </div>
      </div>
      <div className="flex justify-between items-center gap-2">
        <div className="flex flex-wrap xl:flex-nowrap justify-center items-center gap-3">
          <div>
            <p
              className={classNames(
                'text-xs xl:text-sm mb-2 whitespace-nowrap font-medium text-grey opacity-60 leading-9 text-center',
                {
                  'text-bright-red': isCannotBuy,
                  '!text-dark-grey': !isCannotBuy && !selected
                }
              )}
            >
              {isCannotBuy ? '剩下 0 噸無法交易' : `剩下 ${remaining_quantity} 噸可購`}
            </p>
            <div className="flex items-center gap-1.2" onClick={(e) => e.stopPropagation()}>
              <MinusRounded onClick={() => onQuantityAdjust(qty - 1)} />
              <input
                className="min-w-12 max-w-14 rounded-md border border-[#B3B4B4] bg-transparent text-right pr-3.5 text-bright-blue text-xl leading-normal tracking-[0.695px] font-bold flex items-center justify-center"
                type="number"
                value={qty}
                onChange={(e) => {
                  if (isValidNumber(e.target.value)) {
                    onQuantityAdjust(parseInt(e.target.value));
                  }
                }}
              />
              <PlusRounded onClick={() => onQuantityAdjust(qty + 1)} />
            </div>
          </div>

          <p className="text-xl font-bold text-black whitespace-nowrap min-w-[120px] text-center">
            $ {formatNumberByComma(qty * price)}
          </p>
        </div>

        {/* <button className="mr-7">
          <img
            src="/images/cart/ic_delete.svg"
            width={23}
            height={27}
            className="w-6 h-7"
            alt="sacurn"
            onClick={onDeleteCartItem}
          />
        </button> */}
        <DeleteIcon fontSize="medium" sx={{ color: grey[500] }} onClick={onDeleteCartItem} className="cursor-pointer" />
      </div>
    </div>
  );
};

const Heading: FC<HeadingIProps> = ({ children }) => {
  return (
    <div className="border-l-8  border-l-pale-yellow pl-5 text-black 2xl:text-lg text-base font-bold">{children}</div>
  );
};
