import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import classNames from 'classnames';
import { useState } from 'react';

import Button from '@/components/Button';
import DateSelect from '@/components/DateSelect';
import OrderTable from '@/components/Historical/OrderTable';
import Navbar from '@/components/Navbar';
import OutlineRounedSearchBar from '@/components/OutlineRounedSearchBar';
import type { Order, TableHeader } from '@/types/historical';

import HistoricalSidbar from '../Historical/HistoricalSidbar';

type Props = {
  // children?: React.ReactNode;
  title: string;
  color: 'blue' | 'green';
  headers: TableHeader[];
  orders: Order[];
};

const HistoricalLayout = ({ title, color, headers, orders }: Props) => {
  return (
    <div className="w-full relative overflow-hidden bg-neutral-250 h-screen">
      <Navbar className="!bg-navy-blue py-4" />
      <div className="px-8 pt-6">
        <div className="flex items-baseline">
          <h1
            className={classNames('text-2xl mb-4 min-w-[300px]', {
              'text-navy-blue': color == 'blue',
              'text-light-green': color == 'green'
            })}
          >
            <span className="mr-2 font-semibold">|</span>
            {title}
          </h1>
          {orders.length !== 0 && (
            <div className="ml-auto flex w-full justify-end gap-4">
              <DateSelect />
              <OutlineRounedSearchBar placeholder="輸入想要搜尋的碳權名稱" />
              <Button className="!bg-grey flex items-center gap-1 hover:!bg-[#B5B5B6] active:!bg-dark-grey">
                <FileDownloadOutlinedIcon />
                Download
              </Button>
            </div>
          )}
        </div>
        <div className="flex gap-8">
          <HistoricalSidbar color={color} />
          {/* <div>{children}</div> */}
          {orders.length === 0 ? (
            <div className="w-full h-[500px] flex flex-col gap-6 items-center justify-center text-grey">
              <div className="max-w-[400px]">
                <img src="/images/historical-order/emptyOrder.png" />
              </div>
              目前尚未有訂單資訊
            </div>
          ) : (
            <OrderTable headers={headers} orders={orders} />
          )}
        </div>
      </div>
    </div>
  );
};
export default HistoricalLayout;
