import { create } from 'zustand';

import { Employee, ExtendEmployee, Group } from '@/libs/api';
import apiClient from '@/libs/api/client';

import { PatchedEmployeesPatch } from '../libs/api';
import { useCardStore } from './card';
import { ModalType, runTask } from './modal';

type EmployeeState = {
  roleList: Group[];
  employeeList: Employee[];
  getRoleList: (page?: number) => void;
  createEmployee: (data: ExtendEmployee, photo: File) => Promise<boolean>;
  getEmployeeList: (page?: number) => Promise<void>;
  selectedEmployee?: Employee;
  getSelectedEmployee: (
    ...args: Parameters<typeof apiClient.company.companyEmployeeRetrieve>
  ) => Promise<Employee | null>;
  updateEmployeeDetails: (id: number, companyData?: FormData) => void;
  deleteEmployeeAccount: (
    onClick: () => void,
    ...args: Parameters<typeof apiClient.company.companyEmployeeDestroy>
  ) => void;
  adminRestPassword: (userId: number, newPassword: string) => Promise<boolean>;
};
export const useEmployeeStore = create<EmployeeState>((set, get) => ({
  roleList: [],
  employeeList: [],
  selectedEmployee: undefined,
  getRoleList: async (page?: number) => {
    await runTask(async () => {
      const response = await apiClient.company.companyGroupList(page);
      set({ roleList: response.results });
    });
  },
  createEmployee: async (employeeData, photo) => {
    let isSuccess = false;
    await runTask(async () => {
      isSuccess = await useCardStore.getState().checkMemberCard(
        { title: '新增使用者作業' },
        async () => {
          return await apiClient.twid.twidGenPkcs7TbsEmployeeCreate();
        },
        async (twid_record, b64Cert, pkcs1) => {
          const formData = new FormData();
          console.log('employeeData', employeeData);
          Object.keys(employeeData).forEach((key: string) => {
            const keyName = key as keyof typeof employeeData;
            if (!employeeData[keyName]) return;
            formData.append(key, JSON.stringify(employeeData[keyName]));
          });

          // const result = await apiClient.company.companyEmployeeCreate({
          //   ...employeeData
          // });

          // const formData = new FormData();
          formData.append('photo', photo);
          const result = await apiClient.company.companyEmployeeCreate('', formData as unknown as ExtendEmployee);
          console.log('result', result);

          // await apiClient.company.companyEmployeeUploadPhotoCreate(result.id, formData as unknown as EmployeesPhoto);
        }
        // async () => {
        //   const result = await apiClient.company.companyEmployeeCreate(twid_record.toString(), {
        //     ...employeeData,
        //     b64Cert,
        //     pkcs1
        //   });
        //   const formData = new FormData();
        //   formData.append('photo', photo);
        //   await apiClient.company.companyEmployeeUploadPhotoCreate(result.id, formData as unknown as EmployeesPhoto);
        // }
      );
    });
    if (isSuccess) await get().getEmployeeList();
    return isSuccess;
  },
  getEmployeeList: async (page?: number) => {
    await runTask(async () => {
      const response = await apiClient.company.companyEmployeeList(page);
      set({ employeeList: response.results });
    });
  },
  getSelectedEmployee: async (...args) => {
    let employee: Employee | null = null;
    await runTask(async () => {
      const response = await apiClient.company.companyEmployeeRetrieve(...args);
      employee = response;
      set({ selectedEmployee: response });
    });
    return employee;
  },
  updateEmployeeDetails: async (id, employeeData) => {
    await runTask(async () => {
      const response = await apiClient.company.companyEmployeePartialUpdate(id, employeeData as PatchedEmployeesPatch);
      await get().getEmployeeList();
      set({ selectedEmployee: response });
    });
  },
  deleteEmployeeAccount: async (onClick, ...args) => {
    await runTask(
      async () => {
        await apiClient.company.companyEmployeeDestroy(...args);
      },
      {
        onComplete: () => ({
          type: ModalType.DeleteEmployeeAccountSuccess,
          config: {
            buttons: [
              {
                text: '確認',
                onClick
              }
            ]
          }
        })
      }
    );
  },
  async adminRestPassword(userId, newPassword) {
    let isSuccess = false;
    await runTask(async () => {
      await apiClient.company.companyAdminResetPasswordCreate({
        user: userId,
        new_password1: newPassword,
        new_password2: newPassword
      });
      isSuccess = true;
    });
    return isSuccess;
  }
}));
