import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { useFilterOptionsStore } from '@/store/filterOptions';
import { useProductListStore } from '@/store/productList';
import { useWishListStore } from '@/store/wishList';
import { RadioButtonWhiteFilled, RadioButtonWhiteOutline } from '@/svg';
import { CarbonTag } from '@/type';
import { useInnerHeight } from '@/util/useInnerHeight';

import Searchbar from '../Searchbar';
import SelectField from '../SelectInput';
import SortFiltersModal from '../SortFiltersModal';
import LayoutSwitch from './LayoutSwitch';
import Tile from './Tile';

enum SearchType {
  CarbonTitle = '碳權標題',
  CompanyCode = '企業代號'
}

const ProductList = () => {
  const innerHeight = useInnerHeight();
  const productList = useProductListStore((state) => state.productList);
  const updateProductListByFilters = useProductListStore((state) => state.updateProductListByFilters);
  const filters = useProductListStore((state) => state.filters);
  const wishList = useWishListStore((state) => state.wishList);
  const getWishList = useWishListStore((state) => state.getWishList);
  const getFilterOptions = useFilterOptionsStore((state) => state.getFilterOptions);
  const locationOptions = useFilterOptionsStore((state) => state.locationOptions);
  const vintageOptions = useFilterOptionsStore((state) => state.vintageOptions);
  const priceOptions = useFilterOptionsStore((state) => state.priceOptions);
  const selectedTag = useProductListStore((state) => state.filters.tag);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchType, setSearchType] = useState<SearchType>(SearchType.CarbonTitle);
  const [isSearchIcon, setIsSearchIcon] = useState<boolean>(true);
  // const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
  //
  // const onResize = useCallback(() => {
  //   setInnerHeight(window.innerHeight);
  // }, []);
  // const clearResized = useCallback(() => {
  //   window.removeEventListener('resize', onResize);
  // }, []);

  useEffect(() => {
    getFilterOptions();
    updateProductListByFilters({
      keywordType: searchType
    });
    if (wishList.length === 0) getWishList();
    // window.addEventListener('resize', onResize);
    // return clearResized;
  }, []);

  const onSortChange = useCallback(
    (sortType: 'price' | 'vintage', desc: boolean) => {
      updateProductListByFilters({
        desc: desc,
        sort_by: sortType
      });
    },
    [filters.desc]
  );

  return (
    <div className="flex px-6 2xl:px-12 justify-end">
      {/* first col */}
      <div className=" 2xl:w-[40%]">
        <div className="absolute top-0 left-0 h-screen z-[-1]">
          <img
            src={classNames({
              '/images/products/green/side-image.png':
                selectedTag === CarbonTag.Green || selectedTag === CarbonTag.White,
              '/images/products/yellow/side-image.png': selectedTag === CarbonTag.Yellow,
              '/images/products/blue/side-image.png': selectedTag === CarbonTag.Blue,
              '/images/products/other/side-image-other.png': selectedTag === CarbonTag.Other
            })}
            alt="sacurn"
            className="h-full object-cover hidden lg:block"
          />
        </div>
      </div>
      {/* second col */}
      <div className=" 2xl:w-[58%] flex-1 max-w-[950px] 2xl:max-w-full mt-4">
        <LayoutSwitch filters={filters} updateFilter={updateProductListByFilters} />
        {/* <div className="flex gap-3 items-center">
          <div
            className="flex gap-0.5 items-center"
            onClick={() => {
              setSearchType(SearchType.CarbonTitle);
              setSearchValue('');
              setIsSearchIcon(true);
              updateProductListByFilters({
                keywordType: SearchType.CarbonTitle
              });
            }}
          >
            {searchType === SearchType.CarbonTitle ? <RadioButtonWhiteFilled /> : <RadioButtonWhiteOutline />}
            <span className="text-white text-[13px] font-bold leading-normal">碳權標題</span>
          </div>
          <div
            className="flex gap-0.5 items-center"
            onClick={() => {
              setSearchType(SearchType.CompanyCode);
              setSearchValue('');
              setIsSearchIcon(true);
              updateProductListByFilters({
                keywordType: SearchType.CompanyCode
              });
            }}
          >
            {searchType === SearchType.CompanyCode ? <RadioButtonWhiteFilled /> : <RadioButtonWhiteOutline />}
            <span className="text-white text-[13px] font-bold leading-normal">企業代號</span>
          </div>
        </div> */}
        <div className="mt-2 flex flex-wrap items-center gap-4 ">
          <div className="flex justify-end items-center gap-1 2xl:gap-3">
            <Searchbar
              value={searchValue}
              placeholder={`搜尋${searchType}`}
              setValue={(keyword, isSearchButtonTrigger) => {
                setSearchValue(keyword);
                setIsSearchIcon(true);
                if (isSearchButtonTrigger) {
                  updateProductListByFilters({
                    keyword
                  });
                }
              }}
              isSearchIcon={searchValue === '' ? true : isSearchIcon}
              setIsSearchIcon={(value) => setIsSearchIcon(value)}
            />
            <SelectField
              label="Location"
              value={filters.location}
              options={locationOptions}
              handleChange={(location: (typeof locationOptions)[number]['value'] | undefined) => {
                updateProductListByFilters({
                  location
                });
              }}
            />
            <SelectField
              label="Vintages"
              value={filters.vintage}
              options={vintageOptions}
              handleChange={(vintage: (typeof vintageOptions)[number]['value'] | undefined) => {
                updateProductListByFilters({
                  vintage
                });
              }}
            />
            {/* <SelectField
              label="Price"
              value={filters.price}
              options={priceOptions}
              handleChange={(price: string | undefined) => {
                updateProductListByFilters({
                  price
                });
              }}
            /> */}
          </div>
          {/* <div className="hidden 2.5xl:block">
            <SortFiltersModal desc={filters.desc} sortBy={filters.sort_by} onSortChange={onSortChange} />
          </div> */}
          <div className="text-nowrap ml-auto">
            <SortFiltersModal desc={filters.desc} sortBy={filters.sort_by} onSortChange={onSortChange} />
          </div>
        </div>
        {/* <div className="flex justify-end pr-8 mt-2 2.5xl:hidden">
          <SortFiltersModal desc={filters.desc} sortBy={filters.sort_by} onSortChange={onSortChange} />
        </div> */}
        <div className="yellowScrollNoBg pr-4  mt-5 overflow-auto overflow-x-hidden">
          <div className="flex flex-col gap-5" style={{ height: innerHeight - 210 }}>
            {productList.map((product) => (
              <Tile
                key={product.id}
                id={product.id}
                tag={product.carbon_tag}
                image={product.cover_image || '-'}
                name={product.name || '-'}
                location={product.location || '-'}
                rating={product.carbon_rating || '-'}
                standard={product.certification_from || '-'}
                type={product.type || '-'}
                coBenefit={product.co_benefit ? parseInt(product.co_benefit) : 0}
                vintage={product.vintage || '-'}
                minPrice={product.min_price}
                maxPrice={product.max_price}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
