import { useState } from 'react';

import type { Order, TableHeader } from '@/types/historical';
import { OrderStatus } from '@/types/historical';
const headersData = [
  { title: '訂單號碼', key: 'orderNumber' },
  { title: '商品名稱', key: 'productName' },
  { title: '單價(USD)', key: 'unitPrice' },
  { title: '數量(噸)', key: 'amount' },
  { title: '總金額(USD)', key: 'total' }
  // { title: '訂單狀態', key: 'orderStatus' }
  // { title: '付款資訊', key: 'paymentInfo' }
];
const orderFakeData: Order[] = [
  {
    id: 1,
    orderNumber: 'A123456789',
    total: 1000,
    orderStatus: 0,
    fee: 200,
    tax: 25000,
    payExpireTime: '2024-09-01 12:00:00',
    creditProducts: [
      {
        id: 1,
        sid: 'Mr12345678',
        name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
        unitPrice: 500,
        amount: 2,
        total: 1000
      },
      {
        id: 2,
        sid: 'Mr12345678',
        name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
        unitPrice: 500,
        amount: 2,
        total: 1000
      }
    ]
  },
  {
    id: 2,
    orderNumber: 'A123456789',
    total: 1000,
    orderStatus: 2,
    fee: 200,
    tax: 25000,
    compeletedTime: '2024-09-05 12:00:00',
    creditProducts: [
      {
        id: 1,
        sid: 'Mr12345678',
        name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
        unitPrice: 500,
        amount: 2,
        total: 1000
      }
    ]
  },
  {
    id: 3,
    orderNumber: 'A123456789',
    total: 1000,
    orderStatus: 3,
    fee: 200,
    tax: 25000,
    cancelTime: '2024-04-05 12:00:00',
    creditProducts: [
      {
        id: 1,
        sid: 'Mr12345678',
        name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
        unitPrice: 500,
        amount: 2,
        total: 1000
      },
      {
        id: 2,
        sid: 'Mr12345678',
        name: 'Kasigau Corridor II REDD+ Forest Conservation Carbon avoidance Andes Inorganic Soil Carbon Andes Inorganic Soil Carbon Soil Carbon Adffdsaas Iasd',
        unitPrice: 500,
        amount: 2,
        total: 1000
      }
    ]
  }
];

interface TransDataItem {
  tagText: string;
  tagColor: string;
  timeText: string;
  timeKey: string;
}

type TransData = {
  [key: string]: TransDataItem;
};
const transData: TransData = {
  [OrderStatus.Unpaid]: {
    tagText: '待付款',
    tagColor: 'bg-warning-red',
    timeText: '付款截止時間',
    timeKey: 'payExpireTime'
  },
  [OrderStatus.PaymentConfirmationInProgress]: {
    tagText: '',
    tagColor: '',
    timeText: '付款時間',
    timeKey: 'paidTime'
  },
  [OrderStatus.OrderCompleted]: {
    tagText: '已成立',
    tagColor: 'bg-navy-blue',
    timeText: '交易完成日期',
    timeKey: 'compeletedTime'
  },
  [OrderStatus.OrderNotEstablished]: {
    tagText: '未成立',
    tagColor: 'bg-[#414141]',
    timeText: '交易取消日期：',
    timeKey: 'cancelTime'
  }
};

const useOrder = () => {
  const [headers, setHeaders] = useState<TableHeader[]>(headersData);
  const [orders, setOrders] = useState<Order[]>(orderFakeData);
  return {
    headersData,
    transData,
    headers,
    orders,
    setOrders,
    setHeaders
  };
};
export default useOrder;
