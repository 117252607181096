import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import formatDate from '@/helpers/formatDate';

import DatePickerModal from './DatePickerModal';

const DateSelect = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  // const [data, setData] = useState<yup.InferType<typeof schema>>({
  //   range: ''
  // });

  useEffect(() => {
    const startDate = dayjs().subtract(14, 'day').toDate();
    const endDate = dayjs().toDate();
    // onSubmit({
    //   range: calcRange(startDate, endDate)
    // });
    setDateRange([startDate, endDate]);
  }, []);
  return (
    <div className="flex items-center relative">
      {/* date picker modal */}
      {open && (
        <DatePickerModal
          startDate={startDate}
          endDate={endDate}
          setDateRange={(dateList) => {
            if (dateList[0] && dateList[1]) {
              // onSubmit({
              //   range: calcRange(dateList[0], dateList[1])
              // });
            }
            setDateRange(dateList);
          }}
          setOpen={setOpen}
          open={open}
        />
      )}
      <label htmlFor="period" className="block text-base  font-medium leading-6 text-grey whitespace-nowrap">
        期間:
      </label>
      <div
        className="flex items-center cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <div className="ml-[15px] text-dark-grey text-base font-black mr-2 whitespace-nowrap">
          {startDate && endDate ? (
            <>
              {formatDate(startDate)} - {formatDate(endDate)}
            </>
          ) : (
            <>
              {formatDate(new Date())} - {formatDate(new Date())}
            </>
          )}
        </div>
        <img width={10} height={12} src="/images/operation-record/icon_chervon_down.png" alt="arrow" />
      </div>
    </div>
  );
};
export default DateSelect;
