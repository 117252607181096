import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import HistoricalLayout from '@/components/Layout/HistoricalLayout';
import useOrder from '@/hooks/useOrder';
import { useOrderStore } from '@/store/order';
import { OrderStatus } from '@/types/historical';

const OrderList = () => {
  const { headersData, headers, orders, setOrders, setHeaders } = useOrder();
  const { status } = useParams();
  const setPage = useOrderStore((state) => state.setPage);
  useEffect(() => {
    setPage('order-list');
  }, []);
  useEffect(() => {
    if (!Array.isArray(headers)) return;
    if (status === OrderStatus.HistoricalOrder.toString()) {
      setHeaders([...headersData, { title: '訂單狀態', key: 'orderStatus' }]);
    } else if (status === OrderStatus.Unpaid.toString()) {
      setHeaders([...headersData, { title: '付款資訊', key: 'paymentInfo' }]);
    } else {
      setHeaders(headersData);
    }
  }, [status]);
  return <HistoricalLayout title="購買清單" color="blue" headers={headers} orders={orders} />;
};
export default OrderList;
