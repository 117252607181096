import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import PlaylistRemoveOutlinedIcon from '@mui/icons-material/PlaylistRemoveOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import type { SideBarItem } from '@/types/historical';
import { OrderStatus } from '@/types/historical';

import HistoricalSidbarItem from './HistoricalSidbarItem';

const sideBarItems: SideBarItem[] = [
  {
    title: '待付款',
    icon: AccountBalanceWalletOutlinedIcon,
    status: OrderStatus.Unpaid
  },
  {
    title: '已付款確認中',
    icon: LibraryAddCheckOutlinedIcon,
    status: OrderStatus.PaymentConfirmationInProgress
  },
  {
    title: '訂單已完成',
    icon: PlaylistAddCheckOutlinedIcon,
    status: OrderStatus.OrderCompleted
  },
  {
    title: '訂單不成立',
    icon: PlaylistRemoveOutlinedIcon,
    status: OrderStatus.OrderNotEstablished
  },
  {
    title: '歷史訂單',
    icon: AccessTimeOutlinedIcon,
    status: OrderStatus.HistoricalOrder
  }
];
type Props = {
  color: 'blue' | 'green';
};

const HistoricalSidbar = ({ color }: Props) => {
  const navigate = useNavigate();
  const { status } = useParams();
  const location = useLocation();

  const handleStatusChange = (newStatus: OrderStatus) => {
    if (newStatus.toString() === status) return;
    const url = location.pathname.split('/');
    url.splice(url.length - 1, 1, newStatus.toString());
    navigate(url.join('/'));
    // TODO：Get order list by status
  };
  return (
    <div className="flex flex-col gap-2">
      {sideBarItems.map((item, index) => (
        <HistoricalSidbarItem
          key={index}
          item={item}
          color={color}
          isActive={status === item.status.toString()}
          handleStatusChange={handleStatusChange}
        />
      ))}
    </div>
  );
};
export default HistoricalSidbar;
