import { use } from 'i18next';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import HistoricalLayout from '@/components/Layout/HistoricalLayout';
import useOrder from '@/hooks/useOrder';
import { useOrderStore } from '@/store/order';
import { OrderStatus } from '@/types/historical';

const MyStore = () => {
  const { headersData, headers, orders, setOrders, setHeaders } = useOrder();
  const { status } = useParams();
  const setPage = useOrderStore((state) => state.setPage);
  useEffect(() => {
    setPage('my-store');
  }, []);

  useEffect(() => {
    if (status === OrderStatus.HistoricalOrder.toString() && Array.isArray(headers)) {
      setHeaders([...headers, { title: '訂單狀態', key: 'orderStatus' }]);
    } else {
      setHeaders(headersData);
    }
  }, [status]);
  return <HistoricalLayout title="我的賣場" color="green" headers={headers} orders={orders} />;
};
export default MyStore;
