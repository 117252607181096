import { ElementType } from 'react';

export type TableHeader = {
  title: string;
  key: string;
};

export type SideBarItem = {
  title: string;
  icon: ElementType;
  status: OrderStatus;
};
export enum OrderStatus {
  Unpaid,
  PaymentConfirmationInProgress,
  OrderCompleted,
  OrderNotEstablished,
  HistoricalOrder
}
export type Order = {
  id: number;
  orderNumber: string;
  total: number;
  fee: number;
  tax: number;
  payExpireTime?: string;
  paidTime?: string;
  compeletedTime?: string;
  cancelTime?: string;
  paymentInfo?: string;
  orderStatus: OrderStatus;
  creditProducts: creditProduct[];
};
export type creditProduct = {
  id: number;
  sid: string;
  name: string;
  unitPrice: number;
  amount: number;
  total: number;
};
